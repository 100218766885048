import React, { useState } from "react";
import ocmLogo from "../../assets/icons/ocm-logo.svg";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import {
  TextField,
  Grid,
  Typography,
  createTheme,
  ThemeProvider,
  Box,
} from "@mui/material";
const Footer = () => {
  const [email, setEmail] = useState("");
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ height: "5vh", width: "100vw" }} />
      <Row
        sx={{
          //height: "50vh",
          width: "100vw",
          backgroundColor: "#293B56",
          padding: { lg: "4%", md: "5%", xs: "5%" },
          justifyContent: { md: "space-between", xs: "center" },
          flexDirection: { md: "row", xs: "column" },
        }}
        container
      >
        <Grid
          sx={{
            alignSelf: { sm: "flex-start", xs: "center" },
            marginLeft: { md: 0, sm: "10vw", xs: 0 },
            marginRight: { md: "2vw", xs: 0 },
            marginBottom: { md: 0, xs: "3%" },
          }}
          item
          xs={12}
          sm={1}
        >
          <Box sx={{ display: { lg: "block", xs: "none" } }}>
            <img src={ocmLogo} alt="ocm-logo" width={"160px"} />
          </Box>
          <Box sx={{ display: { lg: "none", xs: "block" } }}>
            <img src={ocmLogo} alt="ocm-logo" width={"120px"} />
          </Box>
        </Grid>
        <Row
          container
          sx={{
            justifyContent: "space-between",
            marginLeft: { md: "2vw", sm: "10vw", xs: "2vw" },
            marginBottom: { md: 0, xs: "2%" },
          }}
          item
          xs={12}
          sm={10}
        >
          <Column item xs={6} sm={6} md={2} lg={2} xl={2}>
            <HeaderText>Quick Links</HeaderText>
            {/* <a href="https://play.google.com/store/apps/details?id=com.ogc_mobile_app">
              <Text>OGC Publications</Text>
            </a> */}
            <StyledLink to="/">
              <Text>Home</Text>
            </StyledLink>
            <StyledLink to="/about">
              <Text>About</Text>
            </StyledLink>
            <StyledLink to="/community">
              <Text>Community</Text>
            </StyledLink>
            <StyledLink to="/events">
              <Text>Events</Text>
            </StyledLink>
            <StyledLink to="/news">
              <Text>Press</Text>
            </StyledLink>
            <StyledLink to="/offering">
              <Text>Donations</Text>
            </StyledLink>
          </Column>
          <Column item xs={6} sm={6} md={2} lg={2} xl={2}>
            <HeaderText>Contact</HeaderText>
            <Text>Info@onoriocutane.org</Text>
            <Text>8 The Green, Suite A, Dover, De 19901</Text>
            <Text>United States of America</Text>
            <Text>Tel : +1 617 386 0132</Text>
          </Column>
          <Column item xs={6} sm={6} md={2} lg={2} xl={2}>
            <HeaderText>Legal</HeaderText>
            <StyledLink to="/policies">
              <Text>Terms</Text>
            </StyledLink>
            <StyledLink to="/policies">
              <Text>Privacy Policy</Text>
            </StyledLink>
            <StyledLink to="/policies">
              <Text>Cookie Policy</Text>
            </StyledLink>
            <StyledLink to="/policies">
              <Text>Personnal Info</Text>
            </StyledLink>
          </Column>
          <Column item xs={6} sm={6} md={3} lg={3} xl={3}>
            <HeaderText sx={{ color: "white" }}>NEWSLETTER</HeaderText>
            <Row sx={{ justifyContent: "flex-start", alignItems: "center" }}>
              <StyledTextField
                fullWidth
                id="email"
                name="email"
                margin="dense"
                type={"email"}
                placeholder="Enter Your Email"
                hiddenLabel
                variant="filled"
                size="normal"
                sx={{ width: { md: "10vw", xs: "50vw" }, marginRight: "1vw" }}
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
              <SendButton
                onClick={() => {}}
                sx={{
                  width: { md: "15vw", xs: "40vw", sm: "30vw" },
                  height: "35px",
                  //padding: "5%",
                  alignSelf: "center",
                }}
              >
                <Text sx={{ textAlign: "center" }}>Subscribe</Text>
              </SendButton>
            </Row>
          </Column>
        </Row>
        <Column sx={{ margin: { md: "1% 0", xs: "3% 0" } }}>
          <Divider sx={{ margin: { md: 0, xs: "2% 0" } }} />
          <Row
            sx={{
              justifyContent: "space-between",
              margin: { md: "1% 0", xs: "1% 1%" },
              alignItems: "center",
              flexDirection: { md: "row", xs: "column" },
            }}
          >
            <Text sx={{ padding: "0 0" }}>
              &copy; 2025 Onório Cutane Ministries is DBA of Nations for Christ
              Ministerial Church
            </Text>
            <Row
              sx={{
                //alignSelf: "flex-end",
                justifyContent: "space-between",
                marginTop: { md: 0, sm: "1%", xs: "4%" },
                width: {
                  xl: "13vw",
                  lg: "14vw",
                  md: "18vw",
                  sm: "30vw",
                  xs: "47vw",
                },
              }}
            >
              <a
                href="https://www.facebook.com/Igreja-Ministerial-Na%C3%A7%C3%B5es-para-Cristo-562609503888735/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  alt="facebook"
                  src={require("../../assets/facebook.png")}
                />
              </a>
              <a
                href="https://www.youtube.com/c/Ap%C3%B3stoloOn%C3%B3rioCutane"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img alt="youtube" src={require("../../assets/youtube.png")} />
              </a>
              <a
                href="https://twitter.com/onoriocutane"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img alt="twitter" src={require("../../assets/twitter.png")} />
              </a>
            </Row>
          </Row>
        </Column>
      </Row>
    </ThemeProvider>
  );
};

export default Footer;

const theme = createTheme({
  typography: {
    fontFamily: ["Poppins"],
  },
});
const Column = styled(Grid)({
  display: "flex",
  flexDirection: "column",
});
const Row = styled(Grid)({
  display: "flex",
  flexDirection: "row",
});
const StyledLink = styled(Link)({
  textDecoration: "none",
});
const HeaderText = styled(Typography)({
  color: "#B78400",
  fontWeight: "600",
  fontSize: { lg: "16px", md: "14px", xs: "14px" },
  lineHeight: "21px",
  textAlign: "left",
  //marginLeft: "5px",
  textTransform: "uppercase",
  whiteSpace: "nowrap",
  padding: "3% 0",
});
const Text = styled(Typography)({
  color: "#FFFFFF",
  fontWeight: "400",
  fontSize: "12px",
  lineHeight: "18px",
  textAlign: "left",
  padding: "3% 0%",
});
const Divider = styled(Box)({
  backgroundColor: "#D6D6D661",
  height: "2px",
  width: "90vw",
});
const StyledTextField = styled(TextField)({
  //backgroundColor: "#fff",
  fontSize: "10px",
  input: {
    fontSize: "10px",
    color: "#fff",
    backgroundColor: "transparent",
  },
  "& .MuiOutlinedInput-notchedOutline legend": { display: "none" },
});
const SendButton = styled("button")({
  background: "transparent",
  borderRadius: "2px",
  //borderColor: "#CDCDCD",
  border: "1px solid #CDCDCD",
  //borderWidth: "0.5px",
  "&:hover": {
    backgroundColor: "grey",
  },
  textAlign: "center",
});
